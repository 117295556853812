body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar li:not(:first-child) {
  margin-top: 0;
}

.navbar li:not(:last-child) {
  @apply mr-5;
}


/* .hero {
    height: 222px;
    position:relative;
    font-size:40px;
    text-align:center;
} */
.hero {
  background-image: url('../public/images/pexels-gustavo-fring-8770087.jpg');
  width: 100vw;
  height: 65vh;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
	-moz-filter: blur(5px);
	-o-filter: blur(5px);
	-ms-filter: blur(5px);
	/* filter: blur(3px); */
  filter: brightness(30%);
  padding-top: 30vh;
}

.hero_text {
  position: relative;
  z-index: 2;
  color: white;
  padding-top: 25vh;
}